import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import Layout from '../components/layout/layout';
import FreedomBanner from '../components/freedomBanner/freedomBanner';
import Seo from '../components/seo/seo';

const Covid19ResponsePage = () => {
  return (
    <Layout>
      <Seo
        canonical={`https://www.essentialbehavioral.com/covid-19-response/`}
      />

      <>
        <section className={`py-5`}>
          <Container>
            <Row className={`justify-content-center`}>
              <Col xs={11} md={8}>
                <h1 className={`text-center`}>Covid-19 Response</h1>
                <pre className={`text-center`}>How Clean &amp; Sober Detox Is Protecting Clients</pre>
                <p>Clean &amp; Sober Detox is committed to nurturing and supporting our clients and alumni in a safe environment. We have continued to provide personalized treatment for substance abuse and co-occurring mental health disorders at each of our four facilities, while taking every measure to ensure the safety and long-term health of our clients.</p>
                <p>Here are some of the measures we have taken in response to COVID-19:</p>
                <ul>
                  <li>Facilities have implemented health screenings and nurse evaluations for flu-like symptoms before and after the admission of each patient. Any patients who display symptoms will not be admitted.</li>
                  <li>Each facility has also implemented use of the COVID-19 Rapid Test Cassette, which returns results in 10 minutes.</li>
                  <li>Clinicians screen patients daily by taking their temperature and checking for coughing and shortness of breath.</li>
                  <li>Staff and patients have received hand-washing hygiene training in compliance with CDC guidelines.</li>
                  <li>Housekeeping and maintenance staff have increased cleaning and sanitation of surfaces at all treatment centers and offices.</li>
                  <li>Facilities have suspended family programming, tours, and other nonessential on-site activities. The company is developing a virtual meeting option to allow families to continue to participate in their loved ones’ treatment.</li>
                  <li>Daily communication with employees about health and safety to ensure employees with fever, coughing, or shortness of breath remain at home.</li>
                </ul>
                <p>In addition to on-site protections, Clean &amp; Sober Detox facilities are also offering telehealth sessions to provide high-quality care and counseling under the safest possible conditions.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <FreedomBanner />
      </>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 2248 }) {
      title
      content
      seo {
        metaTitle
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
    }
    wp {
      globalContent {
        globalButtons {
          buttonSettings {
            buttonText
            linkType
            internalLink {
              ... on WpPost {
                id
                uri
              }
              ... on WpPage {
                id
                uri
              }
            }
            externalLink
            htmlClass
          }
        }
        globalContact {
          phone
          address
          map {
            latitude
            longitude
            zoom
          }
        }
      }
    }
  }
`;

export default Covid19ResponsePage;
